<template>
  <div class="flex-auto column" style="margin-bottom: 0;">
    <div class="flex-row align-items-center justify-between padding-bottom-10 border-bottom">
      <div class="bold">我的优惠券</div>
      <div>
        <el-button size="medium" @click="couponOfCode.visible=true;couponOfCode.code='';">兑换优惠券</el-button>
        <el-button type="primary" size="medium" @click="addCouponShow()">领取优惠券</el-button>
      </div>
    </div>
    <template>
      <div class="coupon-list">
        <div class="item margin-top-15" v-for="(item,index) in couponInfo.list" :key="index">
          <div class="detail flex-row align-items-center justify-between">
            <div class="flex-row align-items-center">
              <div class="je bold"><span class="font-size-28">{{item.yhq_je}}</span></div>
              <div class="main">
                <div class="font-size-20">{{item.yhq_lx_sm}}</div>
                <div class="margin-top-5">有效期：{{item.yxq_start}} 至 {{item.yxq_end}}</div>
              </div>
            </div>
            <div class="color-red">待使用</div>
          </div>
          <div class="memo color-sub border">使用规则：{{item.yhq_gz}}</div>
        </div>
      </div>
      <el-empty v-if="!couponInfo.list.length&&!couponInfo.loading" description="暂无可用取优惠券！">
        <el-button type="primary" round @click="addCouponShow()">领取优惠券</el-button>
      </el-empty>
    </template>
    <el-dialog custom-class="custom-dialog" title="待领取优惠券"  :visible.sync="addCouponInfo.visible" :append-to-body="true" width="1000px" top="10vh">
      <div style="height: calc(100vh - 20vh - 100px); overflow-y: auto; overflow-x: hidden;">
        <div class="coupon-list padding-lr-10">
          <div class="item margin-bottom-15" v-for="(item,index) in addCouponInfo.list" :key="index">
            <div class="detail flex-row align-items-center justify-between">
              <div class="flex-row align-items-center">
                <div class="je bold"><span class="font-size-28">{{item.yhq_je}}</span></div>
                <div class="main">
                  <div class="font-size-20">{{item.yhq_lx_sm}}</div>
                  <div class="margin-top-5">有效期：{{item.yxq_start}} 至 {{item.yxq_end}}</div>
                </div>
              </div>
              <div><el-button type="primary" size="small" round @click="receive(item.yhq_id)">领取</el-button></div>
            </div>
            <div class="memo color-sub border">使用规则：{{item.yhq_gz}}</div>
          </div>
        </div>
        <el-empty v-if="!addCouponInfo.list.length&&!addCouponInfo.loading" description="暂无可领取优惠券！"></el-empty>
      </div>
    </el-dialog>
    <el-dialog custom-class="custom-dialog" title="兑换优惠券"  :visible.sync="couponOfCode.visible" :append-to-body="true" width="500px" top="30vh">
      <div  class="flex-row align-items-center">
        <span class="text-nowrap">兑换码：</span><el-input v-model="couponOfCode.code" placeholder="请输入兑换码"></el-input>
      </div>
      <div slot="footer">
        <div class="flex-row justify-end">
          <el-button  type="primary" @click="exchange">兑 换</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import crypto from "@/common/crypto";

export default {
  components: {},
  name: 'AddressList',
  data() {
    return {
      crypto,
      couponInfo:{
        loading:false,
        page:1,
        pageSize:1000,
        list:[]
      },
      addCouponInfo:{
        visible:false,
        loading:false,
        page:1,
        pageSize:1000,
        list:[]
      },
      couponOfCode:{
        visible:false,
        code:'',
      }
    }
  },
  computed:{
    
  },
  methods:{
    
    //获取列表
    getList(){
      this.couponInfo.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.couponInfo.page,
        page_size:this.couponInfo.pageSize,
      }
      this.$instance.get('/yhq/get_my_yhq_list',{params})
      .then(res=>{
        this.couponInfo.loading=false
        if(res.data.code==0){
          this.couponInfo.list=res.data.data
        }
      })
      .catch(()=> {
        this.couponInfo.loading=false
      }); 
    },
    
    addCouponShow(){
      this.addCouponInfo.visible=true
      this.getAddList()
    },
    //获取列表
    getAddList(){
      this.addCouponInfo.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.addCouponInfo.page,
        page_size:this.addCouponInfo.pageSize,
      }
      this.$instance.get('/yhq/get_new_yhq_list',{params})
      .then(res=>{
        this.addCouponInfo.loading=false
        if(res.data.code==0){
          this.addCouponInfo.list=res.data.data
        }
      })
      .catch(()=> {
        this.addCouponInfo.loading=false
      }); 
    },
    //领取优惠券
    receive(id){
      let params= {
        ...this.$store.state.basicParams,
        yhq_id:id
      }
      this.$instance.post('/yhq/receive_yhq',this.$qs.stringify(params))
      .then(res=>{
        
        if(res.data.code==0){
          this.$message({message: '领取成功',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          this.getList()
          this.getAddList()
        }
      })
      .catch(()=> {

      }); 
    },
    //兑换优惠券
    exchange(){
      if(!this.couponOfCode.code){
        this.$message({message: '请输入兑换码',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false;
      }
      let params= {
        ...this.$store.state.basicParams,
        exchange_code:this.couponOfCode.code
      }
      this.$instance.post('/yhq/exchange_yhq',this.$qs.stringify(params))
      .then(res=>{
        if(res.data.code==0){
          this.$message({message: '领取成功',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          this.getList()
          this.couponOfCode.visible=false
        }
      })
      .catch(()=> {

      }); 
    },
  },
  created(){
    this.getList()
  },
  mounted(){ 
    
  },
  beforeDestroy() {
   
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .coupon-list{
  .item {
    .detail{
      position: relative;
      z-index: 2;
      background: #ffe0e0;
      border-radius: 10px;
      padding: 20px 30px;
      .je{
        color: #cf0000;
        width: 100px;
        text-align: center;
      }
      .main{
        color: #8c0000;
      }  
    }
    .detail::before,.detail::after{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: #fff;
      margin-top: -10px;
      top:50%;
    }
    .detail::before{
      left:-10px;
    }
    .detail::after{
      right:-10px;
    }
    .memo{
      border-radius: 10px;
      padding: 25px 30px 10px 30px;
      position: relative;
      z-index: 1;
      margin-top:-15px;
    }
  }
 }
</style>
<style>

</style>
